@import url("https://fonts.googleapis.com/css?family=Chewy");

html {
  --section-background-color: linear-gradient(
    to bottom left,
    rgba(17, 16, 16, 0.582),
    rgba(12, 8, 24, 0.904)
  );

  --image-gradient: linear-gradient(
    to bottom left,
    rgba(17, 16, 16, 0.678),
    rgba(12, 10, 22, 0.863)
  );

  --imp-text-color: #c770f0;
}

html, body {
  overflow-x: hidden;
  font-family: 'Chewy' !important
}

body {
  overflow-x: hidden;
  width: 100%;
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
}

@media (max-width: 767px) {
  .phases-container {
    display: flex;
    justify-content: center;
  }
  
}

@media (max-width: 767px) {
  .catarmy {
    margin-left: 5%;
  }
}

@media (max-width: 767px) {
  .desc1 {
    margin-right: 12%;
  }
}

.ihatemobile {
  display: none;
}

@media (max-width: 767px) {
  .ihatemobile {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    margin-top: 30%;
    margin-left: 15%
  }
}

.highcharts-custom {
  font-family: 'Chewy' !important;
  padding-left: "-15%" !important;
  margin-left: "-15%" !important
}

@media (max-width: 767px) {
  .highcharts-custom {
    /* display: flex;
    justify-content: center;
    margin-left: -80%;
    width: 10%;
    height: 10%;
    font-size: 5px !important; */
    display: none;
  }
}

.purple {
  color: var(--imp-text-color) !important;
}

button:focus {
  box-shadow: none !important;
}

/* --------- */
/*  Preloader */
/* --------- */
#preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999999;
  background-color: #0c0513;
  background-image: url(./Assets/catnobg.png);
  background-repeat: no-repeat;
  background-position: center;
}

#preloader-none {
  opacity: 0;
}

#no-scroll {
  overflow: hidden;
  height: 100vh;
}

/* --------- */
/*Scrollbar   */
/* --------- */

::-webkit-scrollbar {
  width: 7px;
}

/* Track */
/* ::-webkit-scrollbar-track {
  background: #2d1950;
} */

/* Handle */
::-webkit-scrollbar-thumb {
  /* background: rgba(178, 121, 216, 0.959); */
  border-radius: 12px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  /* background: rgba(222, 130, 235, 0.911); */
  border-radius: 12px;
}

/* --------- */
/* Navbar Section  */
/* --------- */
.sticky {
  background-color: #1b1a2ea9 !important;
  transition: all 0.3s ease-out 0s !important;
  box-shadow: 0px 10px 10px 0px rgba(9, 5, 29, 0.171) !important;
  backdrop-filter: blur(15px) !important;
}

.navbar {
  position: fixed !important;
  transition: all 0.3s ease-out 0s !important;
  padding: 0.3rem 2rem !important;
  font-size: 1.2rem !important;
}

.navbar-toggler {
  position: relative !important;
  background-color: transparent !important;
  border-color: transparent !important;
}

.navbar-toggler span {
  display: block !important;
  background-color: black !important;
  height: 4px !important;
  width: 27px !important;
  margin-top: 5px !important;
  margin-bottom: 5px !important;
  transform: rotate(0deg) !important;
  left: 0 !important;
  opacity: 1 !important;
}

.navbar-toggler:focus,
.navbar-toggler:active {
  outline: 0 !important;
}

.navbar-toggler span:nth-child(1),
.navbar-toggler span:nth-child(3) {
  transition: transform 0.35s ease-in-out !important;
  transition: transform 0.35s ease-in-out !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(1) {
  position: absolute !important;
  left: 12px !important;
  top: 10px !important;
  transform: rotate(135deg) !important;
  opacity: 0.9 !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(2) {
  height: 12px !important;
  visibility: hidden !important;
  background-color: transparent !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(3) {
  position: absolute !important;
  left: 12px !important;
  top: 10px !important;
  transform: rotate(-135deg) !important;
  opacity: 0.9 !important;
}

@media (max-width: 767px) {
  .navbar {
    padding: 1rem 2rem !important;
    font-size: 1.4rem !important;
    /* background-color: #181a27 !important; */
    background-color: rgb(0 0 0 / 0%) !important;
    /* background-color: #FAF9F6 !important; */
    /* text-decoration-color: ; */
  }
  .navbar-nav .nav-item a::after {
    display: none !important;
  }
}
.navbar-brand {
  color: rgb(250, 250, 250) !important;
}

.logo {
  height: 1.4em !important;
  width: 2.5em !important;
}

.navbar-nav .nav-link {
  color: black !important;
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.nav-link {
  padding: 0.8rem 1rem !important;
}

@media (max-width: 767px) {
  .nav-link {
    padding: 0.7rem 1rem !important;
  }
}

.navbar-nav .navbar-link {
  color: black !important;
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.navbar-link {
  padding: 0.8rem 1rem !important;
}

@media (max-width: 767px) {
  .navbar-link {
    padding: 0.7rem 1rem !important;
  }
}

.navbar-nav .nav-item {
  position: relative;
  margin-left: 20px;
}

.navbar-nav .nav-item a {
  font-weight: 400;
  transition: all 0.3s ease-out 0s;
  position: relative;
  z-index: 1;
}

.navbar-nav .nav-item a::after {
  content: "";
  position: relative;
  display: block;
  height: 5px;
  width: 0;
  border-radius: 16px;
  background: #F5DEB3;
  bottom: 1px;
  left: 0;
  z-index: -1;
  transition: all 0.3s ease-out 0s;
}

.navbar-nav .nav-item a:hover::after {
  width: 100%;
}

/* --------- */
/* Home section */
/* --------- */
.wave {
  animation-name: wave-animation; /* Refers to the name of your @keyframes element below */
  animation-duration: 2.1s; /* Change to speed up or slow down */
  animation-iteration-count: infinite; /* Never stop waving :) */
  transform-origin: 70% 70%; /* Pivot around the bottom-left palm */
  display: inline-block;
}

.btn-outline-black {
  font-size: 2.8rem !important;
  white-space: nowrap;
  padding-top: 5% !important;
  word-wrap: break-word;
}

.image-container {
  position: relative;
  width: 100%; /* Set to the desired width */
  height: 100%; /* Set to the desired height or adjust as necessary */
  /* width: width of your images;
  height: /* height of your images */
}

.image-container {
  display: flex;
  align-items: center; /* Align items vertically in the center */
  justify-content: center; /* Center items horizontally */
  flex-wrap: wrap; /* Allow items to wrap to the next line on small screens */
}

/* .image-container img {
  width: 100%;
  max-width: 500px;
  transition: opacity 0.75s ease; 
} */

.image-container img {
  max-width: 100%; /* Ensure images do not overflow their container */
  height: auto; /* Maintain aspect ratio */
  transition: opacity 0.75s ease; /* Smooth transition for the fade effect */
}

@media (max-width: 768px) { /* Adjust the breakpoint as needed */
  .image-container {
    flex-direction: column; /* Stack items vertically on small screens */
    justify-content: center !important;
    /* align-items: center; */
    padding-top: 60%;
    padding-bottom: -10%;
  }
  
  .image-container img {
    width: 80%; /* Increase size to make images more visible */
    margin-bottom: 20px; /* Add space between stacked images */
    align-items: center;
    justify-content: center;
  }
}

.image-first, .image-second, .overlay-text {
  position: absolute;
  top: 0;
  right: 0.001%;
  /* object-fit: cover; */
  width: 120%; /* Adjust width if necessary */
  height: 120%;
  object-fit: contain;
  object-position: center center;
}

.emphasis {
  font-size: 46px;
  white-space: nowrap;
  padding-top: 70px;
  padding-left: -10px;
}

@media (max-width: 767px) {
  .emphasis {
    font-size: 46px;
    white-space: normal !important;
    padding-top: 70px;
    padding-left: 17px;
    /* width: 0; */
    /* text-align: center; */
    justify-content: center;
  }
}


.fade-in {
  opacity: 0;
  transform: translateX(-50px); 
  visibility: hidden;
  transition: opacity 0.8s ease-out, transform 0.8s ease-out, visibility 0.8s; 
}

.fade-in-down {
  opacity: 0;
  transform: translateY(-100px); 
  visibility: hidden;
  transition: opacity 0.8s ease-out, transform 0.8s ease-out, visibility 0.8s; 
}

.fade-in-up {
  opacity: 0;
  transform: translateY(50px); 
  visibility: hidden;
  transition: opacity 0.8s ease-out, transform 0.8s ease-out, visibility 0.8s; 
}

.fade-in-top {
  opacity: 0;
  transform: translateX(-30px); 
  visibility: hidden;
  transition: opacity 0.6s ease-out, transform 0.6s ease-out, visibility 0.6s; 
}

.fade-in-num11 {
  opacity: 0;
  transform: translateX(35px); 
  visibility: hidden;
  transition: opacity .8s ease-out, transform .8s ease-out, visibility .8s; 
}

.fade-in-num22 {
  opacity: 0;
  transform: translateX(45px); 
  visibility: hidden;
  transition: opacity .8s ease-out, transform .8s ease-out, visibility .8s; 
}

.fade-in-num33 {
  opacity: 0;
  transform: translateX(55px); 
  visibility: hidden;
  transition: opacity .8s ease-out, transform .8s ease-out, visibility .8s; 
}

.fade-in-num44 {
  opacity: 0;
  transform: translateX(65px); 
  visibility: hidden;
  transition: opacity .8s ease-out, transform .8s ease-out, visibility .8s; 
}

.fade-in-num1 {
  opacity: 0;
  transform: translateX(-70px); 
  visibility: hidden;
  transition: opacity 0.8s ease-out, transform 0.8s ease-out, visibility 0.8s; 
}

.fade-in-num2 {
  opacity: 0;
  transform: translateX(-110px); 
  visibility: hidden;
  transition: opacity 1s ease-out, transform 1s ease-out, visibility 1s; 
}

.fade-in-num3 {
  opacity: 0;
  transform: translateX(-150px); 
  visibility: hidden;
  transition: opacity 1.2s ease-out, transform 1.2s ease-out, visibility 1.2s; 
}

.fade-in-num4 {
  opacity: 0;
  transform: translateX(-190px); 
  visibility: hidden;
  transition: opacity 1.4s ease-out, transform 1.4s ease-out, visibility 1.4s; 
}

.fade-in-visible {
  opacity: 1;
  transform: translateX(0);
  visibility: visible;
}

@media (max-width: 767px) {
  .fade-in, .fade-in-down, .fade-in-up, .fade-in-top,
.fade-in-num11, .fade-in-num22, .fade-in-num33, .fade-in-num44,
.fade-in-num1, .fade-in-num2, .fade-in-num3, .fade-in-num4 {
  transform: none !important;
  opacity: 1 !important;
  visibility: visible !important;
  transition: none !important;
  }
}

.cb-button {
  padding-left: 3%;
  padding-top: -15%
}

@media (max-width: 767px) {
  .cb-button {
    padding-left: 3%;
    /* padding-top: 1% !important; */
    display: flex;
    justify-content: center;
  }
}

.copy-text-button {
  position: relative !important; 
  z-index: 999 !important;
  display: inline-flex;
  align-items: center;
  /* padding-right: 10px; */
  background-color: transparent; /* Initial transparent background */
  color: black; /* Text color, assuming you want it to be white */
  border: 2px solid black; /* White border; adjust as needed */
  padding: 10px 20px; /* Adjust padding as needed */
  font-size: 16px; /* Adjust font size as needed */
  cursor: pointer; /* Changes the cursor to indicate it's clickable */
  border-radius: 10px; /* Rounded corners; adjust the value as needed */
  transition: background-color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transition for background, transform, and box-shadow */
}

.copy-text-button:hover {
  background-color: #000000; /* Background becomes black on hover */
  color: #F5DEB3;
  transform: translateY(-2px); /* Button moves up slightly */
  box-shadow: 0 4px 6px rgba(0,0,0,0.2); /* Adds shadow to create a lifting effect */
}

@media (max-width: 767px) { /* Adjust the max-width as needed */
  .copy-text-button {
    position: relative !important; 
    z-index: 999 !important;
    display: inline-flex;
    align-items: center;
    /* padding-right: 10px; */
    background-color: transparent; /* Initial transparent background */
    color: black; /* Text color, assuming you want it to be white */
    border: 2px solid black; /* White border; adjust as needed */
    padding: 10px 20px; /* Adjust padding as needed */
    font-size: 10px !important; /* Adjust font size as needed */
    cursor: pointer; /* Changes the cursor to indicate it's clickable */
    border-radius: 10px; /* Rounded corners; adjust the value as needed */
    padding-top: "-50%";
    transition: background-color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transition for background, transform, and box-shadow */
  }
}

/* .icon-padding {
  padding-left: 1%
} */

@media (max-width: 767px) { /* Adjust the max-width as needed */
  .btn-outline-black {
    font-size: 2.6rem !important; /* Smaller font size for mobile */
    /* white-space: nowrap !important; */
    /* overflow: hidden !important;
    text-overflow: ellipsis !important;  */
    white-space: wrap;
    margin-left: 9% !important; /* Adjust the margin as needed */
    padding-left: -1% !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    word-wrap: break-word !important;
  }
}

@media (max-width: 767px) { /* Adjust the max-width as needed */
  .the-cats {
    /* font-size: 2.2rem !important; Smaller font size for mobile */
    /* white-space: normal !important; */
    margin-left: 35% !important; /* Adjust the margin as needed */
    /* padding-left: 2% !important; */
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    /* word-wrap: break-word !important; Ensure long words don't cause overflow */
  }
}

/* .tokens {
  font-size: 2.2rem !important;
  white-space: nowrap;
} */

.drop-down-item1 {
  font-size: 25px !important;
  display: flex !important;
  justify-content: flex-start !important;
  align-items: center !important;
  margin-left: 5% !important
}

.drop-down-item2 {
  font-size: 25px !important;
  display: flex !important;
  justify-content: flex-start !important;
  align-items: center !important;
  margin-left: 3% !important
}

@media (max-width: 767px) {
  .drop-down-item1 {
    font-size: 25px !important;
    margin-left: 14% !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }
  .drop-down-item2 {
    font-size: 25px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    margin-left: 14% !important;
  }
}

.main-page-cat {
  /* padding-top: 250; */
  padding-top: 5% !important;
  /* margin-top: 3% !important; */
  padding-left: 5% !important;
  /* padding-bottom: 5; */
}
/* 
.first-image {
  position: relative !important;
} */

.second-image {
  position: relative !important;
  padding-right: 8% !important;
}

/* style={{ paddingLeft: 100, paddingTop: 50, paddingBottom: 5 }} */

@media (max-width: 767px) {
  .main-page-cat {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    padding-top: 20% !important;
    margin-right: 150px !important;
    padding-right: 150px !important;
  }
}

.tokens {
  font-size: 70px;
  /* color: #7E7E7E; */
  color: black;
  white-space: nowrap;
  padding-left: 50px !important;
}

@media (max-width: 767px) {
  .tokens {
    font-size: 55px !important; /* Example of a scaled-down size for mobile */
    white-space: normal !important
  }
}

.questions {
  font-size: 2.1rem !important;
  margin-bottom: -150px !important
}

@media (max-width: 767px) { /* Adjust the max-width as needed */
  .questions {
    font-size: 1.8rem !important; /* Smaller font size for mobile */
    /* margin-left: 1%; Adjust the margin as needed */
    /* word-wrap: break-word; Ensure long words don't cause overflow */
  }
}

.emails {
  color: black;
  font-size: 1.8rem !important;
  /* margin-bottom: -75px !important */
  /* fontSize: '2.4rem', marginBottom: '-75px' */
}

@media (max-width: 767px) {
  .emails {
    font-size: 1.4rem !important; /* Example of a scaled-down size for mobile */
  }
}

@media (max-width: 767px) {
  .numberlol {
    padding-bottom: 10% !important; /* Example of a scaled-down size for mobile */
  }
}

@keyframes wave-animation {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(14deg);
  } /* The following five values can be played with to make the waving more or less extreme */
  20% {
    transform: rotate(-8deg);
  }
  30% {
    transform: rotate(14deg);
  }
  40% {
    transform: rotate(-4deg);
  }
  50% {
    transform: rotate(10deg);
  }
  60% {
    transform: rotate(0deg);
  } /* Reset for the last half to pause */
  100% {
    transform: rotate(0deg);
  }
}
#tsparticles {
  position: fixed !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  width: 100%;
  height: 100%;
}

.home-header {
  padding-top: 80px !important;
}

.home-section {
  position: relative;
  /* z-index: -1; */
  /* background-image: var(--image-gradient), url(./Assets/black.webp); */
  /* background-color: #F7F5E6; */
  background-color: #FAF9F6;
  /* FAF9F6 */
  /* background-color: #F6D7B0; */
  background-position: top center;
  background-repeat: repeat;
  padding-bottom: 175px !important;
  padding-top: 30px !important;
}

.home-content {
  padding: 9rem 0 2rem !important;
  color: whitesmoke;
  text-align: left;
}

.numberlol {
  color: #F5DEB3;
}

.token-heading {
  font-size: 2.4rem;
  color: black;
  padding-top: -90px !important;
}

@media (max-width: 767px) { /* Adjust the max-width as needed */
  .questions {
    font-size: 1.8rem !important; /* Smaller font size for mobile */
    /* margin-left: 1%; Adjust the margin as needed */
    /* word-wrap: break-word; Ensure long words don't cause overflow */
  }
}

.heading {
  font-size: 70px;
  /* color: #7E7E7E; */
  color: black;
  padding-left: 50px !important;
}

.heading-name {
  font-size: 1.7em !important;
  /* color: #7E7E7E; */
  color:black;
  padding-left: 45px !important;
}

.main-name {
  /* color: #cd5ff8; */
  color: #F7CAC9;
}

.Typewriter__wrapper {
  font-size: 2.2em !important;
  color: #be6adf !important;
  font-weight: 600 !important;
}
.Typewriter__cursor {
  font-size: 2.4em !important;
  color: #b562d6 !important;
}

@media (max-width: 767px) {
  .Typewriter__wrapper {
    font-size: 1.4em !important;
    font-weight: 500 !important;
    position: absolute !important;
  }
  .Typewriter__cursor {
    display: none !important;
  }
}

.myAvtar {
  justify-content: center !important;
  padding-top: 9em !important;
}

@media (max-width: 767px) {
  .myAvtar {
    padding-top: 2em !important;
    padding-bottom: 2em !important;
  }
}

.home-about-section {
  position: relative;
  padding-bottom: 70px !important;
  padding-top: -120px !important;
  /* background-color: #80461B; */
  /* background-color: #F6D7B0; */
  background-color: #FAF9F6;
  /* background-color: #C8B7A9; */
}

.overlay-image {
  width: '45%' !important;
  height: '55%' !important;
}

.highcharts-custom .overlay-image {
  position: absolute;
  top: 30%;
  left: 50%;
  width: 70%; /* Adjust based on pie chart size */
  height: auto;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  object-fit: cover;
}


.home-about-description {
  color: black !important;
  padding-top: 100px !important;
  padding-bottom: 20px !important;
  text-align: center;
}

.home-about-body {
  padding-top: 50px;
  font-size: 32px !important;
  text-align: left;
  width: 90%;
  padding-left: 50px;
  padding-bottom: 10%;
}

.home-about-social {
  text-align: center !important;
  padding-top: 25px;
  color: white !important;
}

.home-about-social-links {
  justify-content: center !important;
  padding-top: 45px !important;
  padding-left: 3% !important;
  display: inline-block !important;
  position: relative !important;
  /* padding-inline-start: 0 !important; */
}

.home-buy-section {
  position: relative;
  padding-bottom: 70px !important;
  padding-top: 70px !important;
  background-color: #FAF9F6;
  /* background-color: #80461B; */
  /* background-color: #F6D7B0; */
  /* background-color: #ECE9E2; */
  /* background-color: #C8B7A9; */
}

.home-questions-section {
  position: relative;
  background-color: #FAF9F6;
  /* padding-bottom: 70px !important; */
  padding-top: -70px !important;
}

.home-social-icons {
  position: relative !important;
  display: inline-block !important;
  width: 60px !important;
  height: 60px !important;
  text-align: center !important;
  font-size: 1.75em !important;
  line-height: 2em !important;
  /* background: rgba(255, 255, 255, 0.972) !important; */
  background: transparent !important;
  border-radius: 50% !important;
  transition: 0.5s !important;
  border: 0.25px solid lightgray;
  overflow: hidden; /* This will hide the parts of the image that overflow the container */
}

.home-social-icons img {
  width: 45%; /* This will cover the width of the container */
  height: auto; /* Maintain the aspect ratio */
  min-height: 45%; /* Ensure the image covers the height */
  object-fit: cover; /* Cover the container, some parts of the image may be cropped */
}

.home-social-icons::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: #68187a;
  transition: 0.5s;
  transform: scale(0.9);
  z-index: -1;
}

.home-social-icons:hover::before {
  transform: scale(1.1);
  box-shadow: 0 0 15px #E5AA70;
}

.home-social-icons:hover {
  color: #E5AA70;
  box-shadow: 0 0 5px #E5AA70;
  text-shadow: 0 0 2px #E5AA70;
}

.social-icons {
  display: inline-block !important;
  padding-right: 15px;
  padding-left: 15px;
  border-color: black;
}

.icon-color {
  color: black !important;
}

/* --------- */
/* Footer */
/* --------- */
.footer {
  background-color: rgb(10, 4, 22);
  bottom: 0 !important;
  padding-top: 10px !important;
  padding-bottom: 8px !important ;
}
.footer-copywright {
  text-align: center !important;
  font-size: 24px;
}

.footer-body {
  z-index: 1;
  text-align: center !important;
}

@media (max-width: 767px) {
  .footer-copywright {
    text-align: center !important;
  }

  

  .footer-body {
    text-align: center !important;
  }
}

.footer h3 {
  font-size: 1em;
  color: white !important;
  margin-top: 0.5em !important;
  margin-bottom: 0.5em !important;
}
.footer-icons {
  margin-top: 0.5em !important;
  margin-bottom: 0.5em !important;
  padding: 0 !important;
}

.blockquote-footer {
  color: #a588c0 !important;
}
/* --------- */
/* Projects */
/* --------- */
.project-section {
  position: relative !important;
  padding-top: 150px !important;
  padding-bottom: 30px !important;
  background-image: var(--section-background-color) !important;
}

.project-card {
  padding-top: 50px !important;
  padding-bottom: 50px !important;
  padding-left: 25px !important;
  padding-right: 25px !important;
  height: auto !important;
}

.project-card-view {
  box-shadow: 0 4px 5px 3px rgba(119, 53, 136, 0.459) !important;
  color: white !important;
  background-color: transparent !important;
  opacity: 0.9 !important;
  transition: all 0.5s ease 0s !important;
  height: 100% !important;
}
.project-card-view:hover {
  transform: scale(1.02) !important;
  overflow: hidden !important;
  box-shadow: 0 4px 4px 5px rgba(129, 72, 144, 0.561) !important;
}

.blog-card {
  padding-top: 50px !important;
  padding-bottom: 50px !important;
  padding-left: 25px !important;
  padding-right: 25px !important;
  height: auto !important;
}

.blog-card-view {
  background-color: transparent !important;
  box-shadow: 0 3px 3px 2px rgba(145, 77, 161, 0.459) !important;
  color: white !important;
  transition: all 0.5s ease 0s !important;
  height: 100% !important;
}

.blog-link {
  color: white !important;
  text-decoration: none !important;
}

.blog-link:hover {
  cursor: pointer !important;
}

.blog-card-view:hover {
  transform: scale(1.02) !important;
  overflow: hidden !important;
  box-shadow: 0 3px 3px 5px rgba(155, 88, 173, 0.65) !important;
}

.card-img-top {
  padding: 20px !important;
  opacity: 0.8 !important;
  border-radius: 10px !important;
}

.blog-img {
  padding: 0px !important;
  opacity: 0.8 !important;
  border-radius: 0px !important;
}

.btn-primary {
  color: #fff !important;
  background-color: #623686 !important;
  border-color: #623686 !important;
}

.btn-primary:hover {
  color: #fff !important;
  background-color: #6d20c5d7 !important;
  border-color: #6d20c5d7 !important;
}
.btn:focus {
  outline: none !important;
  box-shadow: none !important;
}
.project-heading {
  color: white !important;
  font-size: 2.3em !important;
  font-weight: 500 !important;
  padding-top: 10px !important;
}

/* --------- */
/* About */
/* --------- */

.about-section {
  position: relative !important;
  padding-top: 150px !important;
  padding-bottom: 30px !important;
  background-image: var(--section-background-color) !important;
  color: white !important;
}

.tech-icons {
  font-size: 4.5em !important;
  margin: 15px !important;
  padding: 10px !important;
  opacity: 0.93 !important;
  border: 1.7px solid rgba(200, 137, 230, 0.637) !important;
  vertical-align: middle !important;
  text-align: center !important;
  border-radius: 5px !important;
  display: table !important;
  box-shadow: 4px 5px 4px 3px rgba(89, 4, 168, 0.137) !important;
  overflow: hidden !important;
  transition: all 0.4s ease 0s !important;
}

@media (max-width: 767px) {
  .tech-icons {
    margin: 10px !important;
  }
}

.tech-icons:hover {
  transform: scale(1.05) !important;
  overflow: hidden !important;
  border: 2.2px solid rgba(197, 115, 230, 0.883) !important;
}
.tech-icon-images {
  padding: 20px !important;
  line-height: 1.6 !important;
}

.quote-card-view {
  border: none !important;
  color: white !important;
  background-color: transparent !important;
}

.about-activity {
  list-style: none !important;
  text-align: left !important;
  padding-left: 1px !important;
}

.icon {
  font-size: 150px !important;
}

.transition {
  background-image: "../../Assets/transitionreal.png";
  background-repeat: repeat
}

.line {
  /* height: 15px; */
  /* border-top: 1px solid #F7CAC9; */
  /* background-color: #F7CAC9; */
  border: 10px solid green;
  border-radius: 5px;
  border: none;
}

.footer-name {
  margin-top: -50px;
  font-size: 3.2rem;
  padding-top: -50px;
  color: #F7CAC9;
}

.hate {
  padding-right: 100px !important;
  padding-left: 0 !important;
  text-decoration-line: underline !important;
  font-size: 50px !important;
  /* padding: 0 !important; */
}

@media (max-width: 767px) {
  .about-img {
    padding-top: 0 !important;
  }
}
/* --------- */
/* Resume */
/* --------- */

.resume-section {
  position: relative !important;
  padding-top: 110px !important;
  padding-bottom: 30px !important;
  background-image: var(--section-background-color) !important;
  color: white !important;
}

.resume {
  padding-top: 50px;
  padding-bottom: 50px;
  justify-content: center;
}

.resume-left {
  padding-right: 80px !important;
}

.resume-right {
  padding-left: 80px !important;
}

@media (max-width: 767px) {
  .resume-left {
    padding-right: 15px !important;
    padding-left: 15px !important;
  }

  .resume-right {
    padding-right: 15px !important;
    padding-left: 15px !important;
  }
}
.resume .resume-title {
  font-size: 2em;
  font-weight: 700;
  padding-top: 30px;
  padding-bottom: 30px;
}

.resume .resume-item {
  padding: 0 0 10px 25px;
  margin-top: -2px;
  border-left: 2px solid #8a49a8;
  position: relative;
}

.resume .resume-item .resume-title {
  line-height: 18px;
  font-size: 0.9em;
  background: #5234795d;
  padding: 8px 15px;
  display: inline-block;
  font-weight: 600;
  margin-bottom: 10px;
}

.resume .resume-item ul {
  padding-left: 20px;
  text-align: justify;
}

.resume .resume-item ul li {
  padding-bottom: 10px;
  list-style: none;
}

.resume .resume-item:last-child {
  padding-bottom: 0;
}

.resume .resume-item::before {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 50px;
  left: -9px;
  top: 0;
  background: #fff;
  border: 2px solid #8a49a8;
}

.like-item {
  padding-top: 10px !important;
  font-size: 1.1em !important;
  font-family: sans-serif !important;
}

.like-btn {
  background-color: #934cce5e !important;
  border-color: #934cce5e !important;
  padding: 0.25rem 0.98rem !important;
  border-radius: 5px !important;
  line-height: 1.4 !important;
  transition: 0.3s ease !important;
}

.like-btn:hover {
  transform: translateY(-2px) !important;
  background-color: #a24dd386 !important;
  border-color: #a24dd386 !important;
}

.animate-like {
  animation-name: likeAnimation;
  animation-fill-mode: forwards;
  animation-duration: 0.85s;
}
@keyframes likeAnimation {
  0% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}

.fork-btn {
  font-size: 1.1em !important;
  padding-top: 10px !important;
}

.fork-btn-inner {
  line-height: 1.4em !important;
  background-color: #934cce5e !important;
  padding: 0.25rem 1.1rem !important;
  vertical-align: middle !important;
  text-align: center !important;
}

.fork-btn-inner:hover {
  transform: translateY(-2px) !important;
  background-color: #a24dd386 !important;
  border-color: #a24dd386 !important;
}
.fork-btn-inner::after {
  display: none !important;
}
